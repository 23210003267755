/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import moment from 'moment';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import StadiumIcon from '@mui/icons-material/Stadium';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Card, CardContent, Divider } from '@mui/material';

const AccountableTimeline: React.FC = () => {
    interface Row {
        type: string;
        name: string;
        date: string;
    }

    const data: Row[] = [
        { type: 'BEKER', name: 'Tom', date: '1-9-2024' },
        { type: 'BEKER', name: 'Bram', date: '8-9-2024' },
        { type: 'BEKER', name: 'David', date: '15-9-2024' },
        { type: 'COMPETITIE', name: 'Eddy', date: '22-09-2024' },
        { type: 'COMPETITIE', name: 'Mark', date: '29-9-2024' },
        { type: 'COMPETITIE', name: 'Mickey', date: '6-10-2024' },
        { type: 'COMPETITIE', name: 'Niels', date: '13-10-2024' },
        { type: 'COMPETITIE', name: 'Richard', date: '20-10-2024' },
        { type: 'BEKER / INHAAL', name: 'Roberto', date: '27-10-2024' },
        { type: 'COMPETITIE', name: 'Roy Horsten', date: '3-11-2024' },
        { type: 'COMPETITIE', name: 'Selcuk', date: '10-11-2024' },
        { type: 'COMPETITIE', name: 'Sief', date: '17-11-2024' },
        { type: 'BEKER / INHAAL', name: 'Sjors', date: '24-11-2024' },
        { type: 'COMPETITIE', name: 'Steven', date: '1-12-2024' },
        { type: 'COMPETITIE', name: 'Sven', date: '8-12-2024' },
        { type: 'BEKER / INHAAL', name: 'Thomas', date: '15-12-2024' },
        { type: 'BEKER / INHAAL', name: 'Tom', date: '12-1-2025' },
        { type: 'BEKER / INHAAL', name: 'Alcide', date: '19-1-2025' },
        { type: 'COMPETITIE', name: 'William', date: '26-1-2025' },
        { type: 'COMPETITIE', name: 'William de Jong', date: '2-2-2025' },
        { type: 'COMPETITIE', name: 'Yordi', date: '9-2-2025' },
        { type: 'COMPETITIE', name: 'Alcide', date: '16-2-2025' },
        { type: 'COMPETITIE', name: 'Bram', date: '23-2-2025' },
        { type: 'BEKER / INHAAL', name: 'David', date: '9-3-2025' },
        { type: 'COMPETITIE', name: 'Eddy', date: '16-3-2025' },
        { type: 'COMPETITIE', name: 'Mark', date: '23-3-2025' },
        { type: 'BEKER / INHAAL', name: 'Mickey', date: '30-3-2025' },
        { type: 'COMPETITIE', name: 'Niels', date: '6-4-2025' },
        { type: 'COMPETITIE', name: 'Richard', date: '13-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Roberto', date: '19-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Roy Horsten', date: '21-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Selcuk', date: '27-4-2025' },
        { type: 'COMPETITIE', name: 'Sief', date: '4-5-2025' },
        { type: 'COMPETITIE', name: 'Sjors', date: '11-5-2025' },
        { type: 'COMPETITIE', name: 'Steven', date: '18-5-2025' },
        { type: 'BEKER / INHAAL', name: 'Sven', date: '25-5-2025' },
        { type: 'BEKER / INHAAL', name: 'Thomas', date: '1-6-2025' },
        { type: 'BEKER / INHAAL', name: 'Mirko', date: '7-6-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '1-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Niels', date: '8-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Richard', date: '15-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Roberto', date: '22-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Roy Horsten', date: '29-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Selcuk', date: '6-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sief', date: '13-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sjors', date: '20-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Steven', date: '27-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sven', date: '3-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Thomas', date: '10-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Alcide', date: '17-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Bram', date: '24-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'David', date: '1-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '8-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Mark', date: '15-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Mickey', date: '12-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '19-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Niels', date: '26-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Richard', date: '2-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Roberto', date: '9-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Roy Horsten', date: '16-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Selcuk', date: '23-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sief', date: '9-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sjors', date: '16-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Steven', date: '23-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sven', date: '30-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Thomas', date: '6-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Tom', date: '13-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'William', date: '19-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'William de Jong', date: '21-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Yordi', date: '27-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Alcide', date: '4-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Bram', date: '11-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'David', date: '18-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '25-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Mark', date: '1-6-2025' },
        { type: 'BIDONS / BALLEN', name: 'Mickey', date: '7-6-2025' },
    ];

    // Separate the lists
    const mainData = data.filter(item => item.type !== 'BIDONS / BALLEN');
    const bidonsBallenData = data.filter(item => item.type === 'BIDONS / BALLEN');

    // Sort both lists
    mainData.sort((a, b) => new Date(a.date.split(/[-/ ]+/).reverse().join('-')).getTime() - new Date(b.date.split(/[-/ ]+/).reverse().join('-')).getTime());
    bidonsBallenData.sort((a, b) => new Date(a.date.split(/[-/ ]+/).reverse().join('-')).getTime() - new Date(b.date.split(/[-/ ]+/).reverse().join('-')).getTime());

    // Tab state
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    // Find the next upcoming event from the combined data
    const today = moment(); // Get today's date as a moment object

    // Reusable function to get the first upcoming event from a data array
    const getFirstUpcomingEvent = (data: Row[]) =>
        data.filter(item => moment(item.date, "DD-MM-YYYY").isSameOrAfter(today))
            .sort((a, b) => moment(a.date, "DD-MM-YYYY").valueOf() - moment(b.date, "DD-MM-YYYY").valueOf())[0];

    // Use the reusable function on both datasets
    const upcomingEvent = getFirstUpcomingEvent(mainData);
    const upcomingEventbidonsBallenData = getFirstUpcomingEvent(bidonsBallenData);

    const renderTimeline = (timelineData: Row[], showItemType: boolean) => (
        <Timeline position="right" nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
            {timelineData.map(item => (
                <TimelineItem key={`${item.name}-${item.date}`}>
                    <TimelineOppositeContent
                        sx={{
                            m: 'auto 0',
                            align: 'right',
                            color: 'text.secondary',
                            fontSize: {
                                xs: '0.75rem', // small screens
                                sm: '0.875rem', // small to medium screens
                                md: '1rem', // medium screens
                                lg: '1.125rem', // large screens
                                xl: '1.25rem' // extra-large screens
                            },
                            wordBreak: 'break-word', // breaks long words to fit within the width
                            overflowWrap: 'break-word' // ensures wrapped words don't overflow
                        }}>
                        {showItemType ? item.type : ''}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        {item.type === "BEKER" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "BEKER / INHAAL" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "COMPETITIE" && (
                            <TimelineDot color="primary" sx={{ marginLeft: '10px' }}>
                                <StadiumIcon />
                            </TimelineDot>
                        )}
                        {item.type === "BIDONS / BALLEN" && (
                            <TimelineDot color="success" sx={{ marginLeft: '10px' }}>
                                <SportsSoccerIcon />
                            </TimelineDot>
                        )}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            {item.name}
                        </Typography>
                        <Typography>{moment(item.date, 'DD-MM-YYYY').format("LL")}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );

    return (
        <Box>
            {upcomingEvent && (
                <Card
                    sx={{
                        backgroundColor: '#f9f9f9',
                        borderRadius: '12px',
                        boxShadow: 3,
                        marginBottom: '16px',
                        overflow: 'hidden'
                    }}
                >
                    <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <EventIcon sx={{ color: 'primary.main' }} />
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                Volgende wedstrijd
                            </Typography>
                        </Box>

                        <Divider sx={{ my: 1.5 }} />

                        {/* Event Date */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                {moment(upcomingEvent.date, 'DD-MM-YYYY').format("LL")}
                            </Typography>
                        </Box>

                        <Divider sx={{ my: 1.5 }} />

                        {/* Main Event Details */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {upcomingEvent.type === 'COMPETITIE' && <SportsSoccerIcon sx={{ color: 'primary.main' }} />}
                            {upcomingEvent.type.includes('BEKER') && <EmojiEventsIcon sx={{ color: 'warning.main' }} />}
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                {upcomingEvent.type}
                            </Typography>
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                - {upcomingEvent.name}
                            </Typography>
                        </Box>

                        {/* Conditionally render BIDONS / BALLEN data if the date matches */}
                        {upcomingEventbidonsBallenData?.date === upcomingEvent.date && (
                            <>
                                <Divider sx={{ my: 1.5 }} />
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <SportsSoccerIcon sx={{ color: 'success.main' }} />
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                        {upcomingEventbidonsBallenData?.type}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                        - {upcomingEventbidonsBallenData?.name}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </CardContent>
                </Card>
            )}

            <Tabs value={selectedTab} onChange={handleChange} centered>
                <Tab label="Teamtas" />
                <Tab label="Bidons / Ballen" />
            </Tabs>
            <Box sx={{ p: 3 }}>
                {selectedTab === 0 && renderTimeline(mainData, true)}
                {selectedTab === 1 && renderTimeline(bidonsBallenData, false)}
            </Box>
        </Box>
    );
};

export default AccountableTimeline;
